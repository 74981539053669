
@import "bootstrap";

.alert-soft-success  {
  background-color: lighten($success, 60%)
}
.alert-soft-danger {
  background-color: lighten($danger, 40%);
}

.alert-soft-warning {
  background-color: lighten($warning, 40%);
}


.alert-auto-open {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  >i {
    position: relative;
    z-index: 10;
  }
  transition: max-height 600ms ease;
  max-height: 80px;
  &:before {

    content:'';
    width:100%;
    z-index: 9;
    height:40px;
    position:absolute;
    left:0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent, white);
  }

  &:focus {
    &:before {
      background: none;
    }
    max-height: 1000px;
  }


}
